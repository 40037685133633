
import { defineComponent, onMounted, computed, reactive, ref } from "vue";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import moduleTableHeader from "@/store/modules/physicalInventory/modules/physical-count-table"
import moduleTable from "@/store/modules/physicalInventory/modules/physical-count-details-table"

import { FormTypes } from "@/core/enums/form-types";
import { translate } from "@/core/plugins/i18n";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { TransferStatus } from "@/core/enums/transfer-status"
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import SwalMessageService from "@/core/services/SwalMessageService";
import { SwalIconType } from "@/core/enums/swal-icon-type";
import _ from "lodash"

export default defineComponent({
    components: {
        ModuleMaster
        , Datatable
        , Datarow
    },
    setup() {
        const store = useStore(); 
        const openModal = ref<HTMLButtonElement>();
        const serviceMessage = new SwalMessageService();
        const router = useRouter();
        const route = useRoute();
        const folioTransfer = ref('');
        const fileInput = ref();
        const file = ref<File | null>();
        const canAutorizatePhysicalCount = computed(() => store.getters['canAutorizatePhysicalCount']);
        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id", Type: "text", VisibleInGrid: false },
            { PropName: "code", HeadLabel: "Codigo", Type: "text", VisibleInGrid: true },
            { PropName: "name", HeadLabel: "Nombre", Type: "text", VisibleInGrid: true },
            { PropName: "unitOfMeasurementName", HeadLabel: "Unidad de medida", Type: "text", VisibleInGrid: true },
            { PropName: "quantityReal", HeadLabel: "Cantidad Real", Type: "text", VisibleInGrid: true },
            { PropName: "quantityWarehouse", HeadLabel: "Cantidad Almacen", Type: "text", VisibleInGrid: true },
            { PropName: "difference", HeadLabel: "Diferencia", Type: "text", VisibleInGrid: true }
        ]);
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar ",
            titleNewCrudAction: "Nuevo",
        });

        const openPDF = ref();
        let pdfMovement = ref("");

        //computed
        const isInProcessFather = computed(() => moduleTableHeader.isInProcess);
        const haveRows = computed(() => moduleTable.haveRows);
        const totalRecords = computed(() => moduleTable.totalRecords);
        const sizePage = computed(() => moduleTable.pagination.pageSize);
        const renderRows = computed(() => formatToDatatable(moduleTable.getRecordsTable, configTable));

        const handleChangePage = (page: number) => {
            moduleTable.CHANGE_PAGE({ currentPage: page });
        }
        const handleCrudAction = async (action: FormTypes, id: string) => {
            //esto ya no es necesario
        }

        onMounted(() => {
            const physicalId = route.query.physicalId;
            const folio = moduleTableHeader.getSelectedFolio;
            //identificamos si existe información del padres mediante el folio
            if (physicalId && physicalId != '' && !folio && folio == '') {
                //si no se tiene el row seleccionado lo buscamos
                moduleTableHeader.searchInfoSelected(physicalId as string).then((isCorrect) => {
                    if (isCorrect) {
                        initialComponent(moduleTableHeader.getSelectedFolio)
                    } else {
                        router.back()
                    }
                });
            } else {
                initialComponent(folio);
            }
        });
        const initialComponent = (folio:string) => {
            folioTransfer.value = folio;
            moduleTable.SEARCH_TABLE_DATA();
            setCurrentPageBreadcrumbs(translate('BREADCRUMB.PHYSICAL_COUNT_DETAILS'), [
                translate('BREADCRUMB.MODULES')
                , translate('BREADCRUMB.PHYSICAL_INVENTORY')
                , { name: translate('BREADCRUMB.PHYSICAL_COUNT_FOLIO', { folio: folio }), to: 'physical-inventory/physical-count' }]);
        }

        const filterByName = _.debounce((filterText: string) => {
            //busca los articulos
            moduleTable.UPDATE_FILTERS({ filterName: filterText })
        }, 550, { leading: false, trailing: true });
    
        const downloadLayout = () => { 
            //descargar plantilla
            moduleTable.dowloadLayout();
        }

        const uploadFile = () => { 
            //subir archivo
            if (file.value) {
                if (haveRows.value) {
                    serviceMessage.confirm(SwalIconType.Question, 'Este conteo ya tiene registros, si sube el archivo sustituira los existentes ¿Seguro quiere sustituir los registros?')
                        .then(accept => { 
                            if (accept) {
                                
                                moduleTable.uploadFile(file.value).then(correct => { 
                                    if (correct) { 
                                        serviceMessage.success("Se subio correctamente el archivo")
                                        moduleTable.SEARCH_TABLE_DATA();
                                        fileInput.value.value = '';
                                    }
                                })
                             }
                        })
                } else { 
                    //llamar al servicio
                    moduleTable.uploadFile(file.value).then(correct => {
                        if (correct) {
                            serviceMessage.success("Se subio correctamente el archivo")
                            moduleTable.SEARCH_TABLE_DATA();
                            fileInput.value.value = '';

                        }
                    })
                }
            }
        }
        const accepted = () => { 
            if (haveRows.value) {
                moduleTable.authorize();
            } else { 
                serviceMessage.error("Debe importar su información para autorizar")
            }
        }
        const onFileChanged = ($event: Event) => {
            const target = $event.target as HTMLInputElement;
            if (target && target.files) {
                file.value = target.files[0];
            }
         }

        return {
            headers,
            openPDF,
            pdfMovement

            , totalRecords
            , sizePage
            , renderRows
            , labels
            , openModal
            , handleChangePage
            , handleCrudAction
            , TransferStatus
            , isInProcessFather
            , canAutorizatePhysicalCount
            , fileInput
            , print
            , downloadLayout
            , uploadFile
            , accepted
            , onFileChanged
            , filterByName
        }
    }
})
