import Modules from '@/store/enums/Modules';
import store from '@/store';
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import PaginationRequest from '@/models/general/PaginationRequest';
import { Mutations } from '@/store/commons/MutationsTable';
import { SaveValuesToStore } from '@/core/shared/AssingProps';
import { Actions } from '@/store/commons/ActionsTable';
import TablePhysicalCountDetailsState from '../../model/TablePhysicalCountDetailsState';
import FiltersPhysicalInventoryDetails from '@/models/physicalInventory/filters/FiltersPhysicalInventoryDetails';
import PhysicalInventoryDetailRow from '@/models/physicalInventory/PhysicalInventoryDetailRow';
import PhysicalInventoryService from '@/core/services/physicalInventory/PhysicalInventoryService';

const defaultFilters = {
} as FiltersPhysicalInventoryDetails

@Module({dynamic: true, store, namespaced: true, name: Modules.TablePhysicalCountDetailsModule})
class TablePhysicalCountDetailsModule extends VuexModule implements TablePhysicalCountDetailsState {
    records = [] as PhysicalInventoryDetailRow[];
    filters = defaultFilters;
    totalRecords = 0;
    pagination = {
        currentPage: 1
        , pageSize: 10
    } as PaginationRequest;
    loading = false;

    get getRecordsTable(): PhysicalInventoryDetailRow[] { 
        return this.records;
    }

    get haveRows(): boolean { 
        return this.records.length > 0
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: PhysicalInventoryDetailRow[]){
        this.records = table;
    }
     @Mutation
    [Mutations.RESET_FILTERS](){
         this.filters = defaultFilters;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: Partial<PaginationRequest>){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: Partial<FiltersPhysicalInventoryDetails>) {      
        SaveValuesToStore(this.filters, filters, true);
    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }

     /**
     * Se obtienen los datos de la tabla de transferencias creadas mediante la paginación
     */
     @Action
     async [Actions.SEARCH_TABLE_DATA]() {
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
         const physicalId = this.context.rootState[Modules.TablePhysicalCountModule]?.rowSelected?.id ?? '';
         if (physicalId) { 
              const resp = (await (service.getTableDetails(physicalId, this.filters, this.pagination)
             .finally(() => this.context.commit(Mutations.SET_VALUE_LOADING, false)))).data ??
             {
                 data: [] as PhysicalInventoryDetailRow[]
                , totalPages: 0
                , currentPage: this.pagination.currentPage
         }
        // const data = resp.data.map(r => { return {...r, createdAt: } })
         this.context.commit(Mutations.SET_TABLE_DATA, resp.data);
         this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
         this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalRecords);
         }
        
    }
 
     /**
        * Realiza un busqueda con la nueva paginación
        * @param pagination paginación de la tabla
        */
     @Action
     async [Actions.CHANGE_PAGE](pagination: Partial<PaginationRequest>){
           this.context.commit(Mutations.SET_PAGINATION, pagination);
           this.context.dispatch(Actions.SEARCH_TABLE_DATA);
     }
 
     /**
      * Filtros que se aplican y despues se hace la busqueda de los datos
      * @param filters filtros nuevos
      */
     @Action
     async [Actions.UPDATE_FILTERS](filters: Partial<FiltersPhysicalInventoryDetails>) {
         //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
         this.context.commit(Mutations.SET_FILTERS, filters);
         this.context.commit(Mutations.SET_PAGINATION, {
           currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
         });
         this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }

    @Action
    async dowloadLayout() {
         const physicalId = this.context.rootState[Modules.TablePhysicalCountModule]?.rowSelected?.id ?? '';
        if (!physicalId) { return }
        return service.downloadLayout(physicalId);
    }

    @Action
    async uploadFile(file:any) {
         const physicalId = this.context.rootState[Modules.TablePhysicalCountModule]?.rowSelected?.id ?? '';
        if (!physicalId) { return }
        return service.uploadFile(physicalId, file).then(resp => resp);
    }

     @Action
    async authorize() {
         const physicalId = this.context.rootState[Modules.TablePhysicalCountModule]?.rowSelected?.id ?? '';
        if (!physicalId) { return }
        return service.authorize(physicalId).then(resp => resp);
    }
} 

const service = new PhysicalInventoryService();


export default getModule(TablePhysicalCountDetailsModule);
